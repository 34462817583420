import { useI18n } from 'vue-i18n';
import { genders } from '~/models/genders';

export const getGenders = () => {
    const { t } = useI18n();
    return genders.map((item) => {
        return {
            label: t(item.toLowerCase()),
            value: item.toUpperCase()
        };
    });
}
